import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'

import { Alert, Button, Divider, Fade, Typography } from "@mui/material";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DownArrow from '@mui/icons-material/KeyboardArrowDown';

import { axios_post_call } from '../../components/AxiosCall';
import { useLocation } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import { connect } from 'react-redux';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function Summary({ player }) {
    const [tab_index, set_tab_index] = useState(0);
    const [insights, set_insights] = useState(false)
    const [summary, set_summary] = useState(false)

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);


    function secondsToTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        if (hours > 0) {
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        } else {
            return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        }
    }





    const change_youtube_video_time = async (time) => {
        console.log(player.seekTo);
        if (player) {
            player.seekTo(time);
        } else {
            console.log("Reloading ext to find youtube video element");
            setTimeout(change_youtube_video_time, 250);
        }
    };

    useEffect(() => {
        set_insights(JSON.parse(`[
            {
                "insights": [
                   
                    "🍲 Rock soup metaphor for transforming rocks into useful materials like smartphones and computers",
                    "⚡ Process of creating pure silicon through cooking rocks and coal with the power of thunder",
                    "🔮 Silicon as a single crystal with aligned atoms and the formation of hands for high-fiving electrons",
                    "🎬 Introduction to diode, transistor, and logic gate",
                    "🖥 Creation of the first silicon-based computer by combining thousands of transistors"
                ],
                "title": "💻 Transformation of rocks and the creation of electronic components"
            },
            {
                "insights": [
                    "⚙ Use of stencils and microscopic techniques to create tiny transistors",
                    "💹Challenges of 3D architecture and trench formation",
                    "⌨ Development of computer instructions and the C programming language",
                    "💾 Advancements in transistor technology for memory, multimedia, and communication",
                    "🚀Exploration of the potential intelligence of rocks through multiplication"
                ],
                "title": "⌚ Microscopic fabrication and advancements in transistor technology"
            }
        ]`))


        set_summary(JSON.parse(`{
                "title": "📚 Journey from Rocks to Smartphones: Unveiling the Secrets of Silicon",
                "summary": [
                    {
                        "emoji": "🌪️",
                        "startTime": "0.0",
                        "summary": "The video introduces the concept of transforming rocks into valuable materials like smartphones and computers. It uses the metaphor of 'rock soup', where rocks are cooked with the power of thunder to produce useful components. 🧪🌩️"
                    },
                    {
                        "emoji": "🔬",
                        "startTime": "13.5",
                        "summary": "Silicon, the key ingredient in electronic devices, is discussed. It is described as a single crystal with aligned atoms, forming hands for high-fiving electrons. The video explains the process of creating pure silicon and how it can be used to make solar panels and transistors. ⚛️👋"
                    },
                    {
                        "emoji": "💡",
                        "startTime": "68.46",
                        "summary": "The concepts of diodes, transistors, and logic gates are introduced. Diodes allow electricity to flow in only one direction, while transistors act as a switch controlled by electricity. Logic gates, like those in Minecraft, enable basic mathematical operations using binary code. ⚙️🕹️"
                    },
                    {
                        "emoji": "🔌",
                        "startTime": "113.759",
                        "summary": "The video explores the evolution of transistors, leading to the creation of the first ever silicon-based computer. Thousands of transistors were combined to perform calculations, but they needed cables to control them individually. Computer instructions and the C programming language simplified the process. 🖥️💾"
                    },
                    {
                        "emoji": "🔍",
                        "startTime": "219.72",
                        "summary": "Microscopic fabrication techniques are discussed, including the use of stencils and the challenge of creating a 3D architecture. The video explains the process of creating tiny transistors and the advancements in transistor technology for memory, multimedia, and communication purposes. 🧫🔬"
                    },
                    {
                        "emoji": "🤖",
                        "startTime": "325.84",
                        "summary": "The potential of rocks for intelligence is explored through the idea of using them for multiplication. The video concludes by highlighting the continuous advancements in transistor technology and the exploration of new possibilities in the field of electronics. 🗺️🚀"
                    }
                ]
            }`))

    }, [])


    return (
        <Box sx={{ overflow: "hidden", flex: 1, backgroundColor: "white", width: 1, overflow: "auto", borderRadius: 3, boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)" }} className="iframe">
            <Box sx={{ width: "100%" }}>
                {alert ? (
                    <Alert
                        onClose={() => {
                            set_alert(false);
                        }}
                        severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}

                        sx={{
                            p: 2,
                            mb: 2,
                            width: "95vw",
                            zIndex: 9923213,
                        }}
                    >
                        {alert}
                    </Alert>
                ) : (
                    ''
                )}
                <LoadingScreen loading={loading} />
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tab_index}
                        onChange={(event, index) => { set_tab_index(index) }}
                    >
                        <Tab {...a11yProps(0)}
                            label={<Button variant='gray_button' >Insights</Button>}
                            sx={{ height: '60px', width: '125px', }}
                        />

                        <Tab {...a11yProps(1)}
                            label={<Button variant='gray_button' >Summary</Button>}
                            sx={{ height: '60px', width: '125px', }}
                        />




                    </Tabs>
                </Box>
                <CustomTabPanel value={tab_index} index={0}>
                    {insights ? (
                        insights.map((insight) => {
                            return (
                                <Box sx={{ mb: 1 }}>
                                    <Typography align="left" variant="h3" mb={1}>
                                        {insight.title}
                                    </Typography>
                                    <Box sx={{ margin: "auto", ml: 2 }}>
                                        {insight.insights.map((insight_point) => {
                                            return (
                                                <Typography variant="subtitle2" sx={{ textAlign: "left", mb: 3 }}>
                                                    {insight_point}
                                                </Typography>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            );
                        })
                    ) : ""}

                </CustomTabPanel>

                <CustomTabPanel value={tab_index} index={1}>
                    {summary ? (
                        <Box>
                            <Typography align="left" variant="h3" mb={1}>
                                {summary.title}
                            </Typography>
                            {summary.summary.map((point, index) => {
                                return (
                                    <Box sx={{ margin: "auto", mt: 2 }}>
                                        <Box sx={{ display: "flex" }}>
                                            <Box sx={{ flex: 0, mr: 1 }}>
                                                <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
                                                    {point.emoji}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
                                                    <span
                                                        className="timestamp-link"
                                                        style={{
                                                            fontSize: 18,
                                                            textDecoration: "underline",
                                                            color: "rgb(234, 36, 86)",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            change_youtube_video_time(point.startTime);
                                                        }}
                                                    >
                                                        {secondsToTime(point.startTime)}
                                                    </span>{" "}
                                                    {point.summary}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })
                            }
                        </Box>
                    ) : ""
                    }
                </CustomTabPanel>
            </Box>
        </Box>
    )
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(Summary);
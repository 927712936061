import { Box, Typography, Button, Alert } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import YouTube from 'react-youtube';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { axios_get_call } from '../../components/AxiosCall';
import LoadingScreen from '../../components/LoadingScreen';


const SummaryDetail = ({ user }) => {
  const location = useLocation();

  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  const [video_id, set_video_id] = useState(false);
  const [category, set_category] = useState(false);
  const [video_details, set_video_details] = useState(false);
  const [player, setPlayer] = useState(null);
  const [related_videos, set_related_videos] = useState(false);

  const onReady = (event) => {
    event.target.pauseVideo();
    setPlayer(event.target);

  };

  function secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    if (hours > 0) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    } else {
      return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }
  }


  function formatTitle(inputString) {
    // Split the input string by underscores
    const words = inputString.split('_');

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

    // Join the words with spaces to create the formatted title
    const formattedTitle = capitalizedWords.join(' ');

    return formattedTitle
  }




  const change_youtube_video_time = async (time) => {
    console.log(player.seekTo);
    if (player) {
      player.seekTo(time);
      window.scrollTo(0, 0);

    } else {
      setTimeout(change_youtube_video_time, 250);
    }
  };

  const get_video_details = async () => {
    let url = '/summary/get-video-details/';
    let res = await axios_get_call(url, { video_id }, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      set_video_details(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  }

  const get_related_videos = async () => {
    let url = '/summary/get-related-videos/';
    let res = await axios_get_call(url, { category, video_id }, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      set_related_videos(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  }



  useEffect(() => {
    if (video_id) {
      get_video_details()
    }
  }, [video_id])


  useEffect(() => {
    if (category) {
      get_related_videos()
    }
  }, [category])

  useEffect(() => {
    let url_parameters = location.pathname.split("summaries/")[1].split("/")
    set_category(url_parameters[0])
    set_video_id(url_parameters[1])
  }, [])

  return (
    <Box sx={{ pt: 5, maxWidth: "1255px", m: "auto" }}>
      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
          sx={{
            p: 2,
            mb: 2,
            width: 1,
            zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ''
      )}
      <LoadingScreen loading={loading} />
      {video_details.title &&
        <Box sx={{ p: 4, mb: 2, borderRadius: 3, bgcolor: "white" }}>
          <Typography variant='h1' >{video_details.title}</Typography>
        </Box>
      }
      {video_details ?
        <Box sx={{ display: 'flex', justifyContent: { lg: "space-between", md: "space-between", sm: "center", xs: "center" }, flexDirection: { lg: "row", md: "row", sm: "column-reverse", xs: "column-reverse" }, }}>

          <Box sx={{ flex: 1, }}>
            <Box sx={{ mr: 2, mb: 4, bgcolor: "white", p: 4, borderRadius: 3 }}>
              <Typography variant='h2' my={4}>Key Points</Typography>
              {video_details.insights && video_details.insights.map((insight) => {
                return (
                  <Box sx={{ mb: 1 }}>
                    <Typography align="left" variant="h3" mb={1}>
                      {insight.title}
                    </Typography>
                    <Box sx={{ margin: "auto", ml: 2 }}>
                      {insight.insights.map((insight_point) => {
                        return (
                          <Typography variant="subtitle2" sx={{ textAlign: "left", mb: 3 }}>
                            {insight_point}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ mr: 2, my: 4, bgcolor: "white", p: 4, borderRadius: 3 }}>
              <Typography variant='h2' my={4}>Summary</Typography>
              {video_details.summary ? (
                <Box>
                  <Typography align="left" variant="h3" mb={1}>
                    {video_details.summary.title}
                  </Typography>
                  {video_details.summary.summary.map((point, index) => {
                    return (
                      <Box sx={{ margin: "auto", mt: 2 }}>
                        <Box sx={{ display: "flex" }}>
                          <Box sx={{ flex: 0, mr: 1 }}>
                            <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
                              {point.emoji}
                            </Typography>
                          </Box>
                          <Box sx={{ flex: 1 }}>
                            <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
                              <span
                                className="timestamp-link"
                                style={{
                                  fontSize: 18,
                                  textDecoration: "underline",
                                  color: "rgb(234, 36, 86)",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  change_youtube_video_time(point.startTime);
                                }}
                              >
                                {secondsToTime(point.startTime)}
                              </span>{" "}
                              {point.summary}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })
                  }
                </Box>
              ) : ""}
            </Box>
          </Box>
          <Box sx={{ ml: 2, flex: 1, display: "flex", flexDirection: "column", justifyContent: "right", textAlign: "right", alignItems: "right", float: "right" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{ borderRadius: 0, width: 1 }}
            >
              Install chrome extension
            </Button>
            {video_details &&
              <YouTube
                videoId={video_details.youtube_video_id}
                opts={{
                  height: '100%',
                  width: '100%',
                }}
                className="iframe"
                id="video"
                onReady={onReady}
                style={{ width: '100%' }}
                playerVars={{
                  autoplay: 1
                }}

              />}
          </Box>
        </Box>
        : <Typography variant='subtitle2' align="center">Getting summary</Typography>}
      <Box>
      {related_videos && related_videos.length > 0 &&
        <Typography mt={6} mb={0} variant='h2'>Related Videos</Typography>
      }

        <Box sx={{
          display: 'flex',
          alignItems: 'center',justifyContent: 'center',
          bgcolor: "#f9f9f9",
          flexWrap: "wrap",
          borderRadius: 3,
          p: 2
        }}>
          {related_videos && related_videos.related_videos.map((video) => {
            return (
              <Box
                onClick={() => {
                  window.open(video.summary_url, "_blank")
                }}
                sx={{
                  borderRadius: 4,
                  minWidth: '420px',
                  height: '268px',
                  overflow: "hidden",
                  display: 'flex', alignItems: 'center', justifyContent: 'center',
                  textAlign: "center",
                  margin: 4,
                  position: 'relative',
                  '&:hover .textOverlay': {
                    display: 'flex', // Show the text on hover
                  },

                }}>
                <Box
                  className="textOverlay"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'none', // Initially hide the text
                    background: 'rgba(0, 0, 0, 0.8)', // Background color for the text overlay
                    color: 'white', // Text color
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 2,
                    cursor: "pointer"
                  }}
                >
                  <Typography variant="subtitle2" align="center" sx={{ color: "white" }}>
                    {video.title}
                  </Typography>
                </Box>
                <img src={video.thumbnail} width={'100%'} />
              </Box>
            )
          })}
        </Box >

        <Typography mt={6} mb={0} variant='h2'>Discover new summaries</Typography>

        <Box sx={{
          display: 'flex',
          alignItems: 'center',justifyContent: 'center',
          bgcolor: "#f9f9f9",
          flexWrap: "wrap",
          borderRadius: 3,
          p: 2
        }}>
          {related_videos && related_videos.other_videos.map((video) => {
            return (
              <Box
                onClick={() => {
                  window.open(video.summary_url, "_blank")
                }}
                sx={{
                  borderRadius: 4,
                  minWidth: '420px',
                  height: '268px',
                  overflow: "hidden",
                  display: 'flex', alignItems: 'center', justifyContent: 'center',
                  textAlign: "center",

                  margin: 4,
                  position: 'relative',
                  '&:hover .textOverlay': {
                    display: 'flex', // Show the text on hover
                  },

                }}>
                <Box
                  className="textOverlay"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'none', // Initially hide the text
                    background: 'rgba(0, 0, 0, 0.8)', // Background color for the text overlay
                    color: 'white', // Text color
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 2,
                    cursor: "pointer"
                  }}
                >
                  <Typography variant="subtitle2" align="center" sx={{ color: "white" }}>
                    {video.title}
                  </Typography>
                </Box>
                <img src={video.thumbnail} width={'100%'} />
              </Box>
            )
          })}
        </Box >
      </Box >
    </Box >
  )
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(SummaryDetail);


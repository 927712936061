import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Link, Typography } from '@mui/material'

import { axios_get_call, axios_post_call } from '../../components/AxiosCall';
import Container from '../../components/Container';
import LoadingScreen from '../../components/LoadingScreen';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Subscription = ({ user }) => {

    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [stripe_billing_dashboard_address, set_stripe_billing_dashboard_address] = useState(false);
    const [has_subscription, set_has_subscription] = useState(false);
    const [subscription_type, set_subscription_type] = useState('yearly');
    const navigate = useNavigate()

    const create_checkout_session = async () => {
        let url = '/subscription/create-checkout-session/';
        let res = await axios_post_call(url, { subscription_type }, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            window.open(res.data.response, "_blank");
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    const get_stripe_billing_dashboard_address = async () => {
        let url = '/subscription/get-stripe-billing-dashboard-address/';
        let res = await axios_get_call(url, {}, set_loading, set_alert);

        if (res.data.status == 1) {
            set_loading(false);
            set_stripe_billing_dashboard_address(res.data.response);
        } else if (res.data.status == 0) {
            set_alert(res.data.response);
            set_loading(false);
        }
    }

    useEffect(() => {
        get_stripe_billing_dashboard_address()
    }, [])

    useEffect(() => {
        if (user) {
            if (stripe_billing_dashboard_address) {
                if (user.subscription_id) {
                    set_has_subscription(true)
                    // setTimeout(() => {
                    //     console.log(stripe_billing_dashboard_address['url']);
                    //     window.open(stripe_billing_dashboard_address['url'], "_blank")
                    // }, 2000);
                }
            }
        }
    }, [user, stripe_billing_dashboard_address])

    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])

    return (
        user ? <Box>

            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
                    sx={{
                        p: 2,
                        mb: 2,
                        width: 1,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}
            <LoadingScreen loading={loading} />
            <Typography align='center' variant='h1'>Subscription</Typography>
            <Box sx={{ my: 2 }}></Box>
            <Box sx={{ alignItems: "left", justifyContent: "left", textAlign: "left", }} >
                <Container bgcolor="white">
                    {has_subscription ?
                    <Typography variant='subtile2'><Link target="_blank" href={stripe_billing_dashboard_address['url']}>Click here</Link> to visit stripe's subscription dashboard</Typography>
                         :
                        <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                            <Box sx={{ flex: 1 }}>
                                <Box>
                                    <Typography align='left' variant='h2'>What you get</Typography>
                                </Box>
                                <Box sx={{ p: 2 }}>
                                    <Typography align='left' variant="h3" >
                                        ♾️ Unlimited summaries
                                    </Typography>
                                </Box>
                                <Box sx={{ p: 2 }}>
                                    <Typography align='left' variant="h3" >
                                        🚀 No video length limit
                                    </Typography>
                                </Box>
                                <Box sx={{ p: 2 }}>
                                    <Typography align='left' variant="h3" >
                                        🌍 Summary translations
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box
                                        onClick={() => {
                                            set_subscription_type("yearly")
                                        }}
                                        sx={{

                                            cursor: "pointer", px: 4, py: 4, m: 2, border: "5px solid rgb(70, 123, 152)",
                                            minWidth: "300px", width: '500px', borderRadius: 6,
                                            bgcolor: subscription_type == "yearly" ? "secondary.main" : "rgb(70, 123, 152, 0.1)",
                                            color: subscription_type == "yearly" ? "white" : "black",
                                            "&:hover": {
                                                bgcolor: "secondary.main",
                                                color: "white"
                                            }
                                        }}>
                                        <Typography align="left" variant='h2' >7-Day free trial</Typography>
                                        <Typography align="left" variant='subtitle2x' >Then $4.99/mo  • Billed annually</Typography>
                                    </Box>
                                    <Box onClick={() => {
                                        set_subscription_type("monthly")
                                    }}
                                        sx={{
                                            cursor: "pointer", px: 4, py: 4, m: 2, border: "5px solid rgb(70, 123, 152)", minWidth: "300px", width: '500px', borderRadius: 6,
                                            bgcolor: subscription_type == "monthly" ? "secondary.main" : "rgb(70, 123, 152, 0.1)",
                                            color: subscription_type == "monthly" ? "white" : "black",
                                            "&:hover": {
                                                bgcolor: "secondary.main",
                                                color: "white"
                                            }
                                        }}>
                                        <Typography align="left" variant='h2' >Monthly / $9.99</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ p: 2 }}>
                                    <Button variant='contained' sx={{ minWidth: "300px", width: '500px', height: "70px", fontSize: '24px' }} onClick={() => {
                                        // window.open(stripe_billing_dashboard_address.url, "_blank"); window.location.reload() 
                                        create_checkout_session()
                                    }}>Start my free trial</Button>
                                    <Typography variant='subtitle2' sx={{ color: "DarkGreen" }}>If you're not satisfied, we'll refund your money with no questions asked.</Typography>
                                </Box>
                            </Box>

                        </Box>
                    }
                </Container>
            </Box>
        </Box> : ''
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(Subscription);
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { connect } from "react-redux";
import Summary from "./Summary";
import chrome_icon from "../../static/media/chrome-icon.png"

import YouTube from 'react-youtube';


const LandingPage = ({ user }) => {
  const navigate = useNavigate();
  const [player, setPlayer] = useState(null);

  const onReady = (event) => {
    event.target.pauseVideo();
    setPlayer(event.target);

  };


  return (
    <Box sx={{ maxWidth: "1255px", m: "auto" }}>
      <Box sx={{ mx: 2, display: "flex", pt: 8, pb: 6, minHeight: { md: "400px", sm: "300px", xs: "300px" }, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="h1" sx={{ fontSize: { lg: 70, md: 60, sm: 50, xs: 35 } }} className="main-heading">
            Save time on long videos, <br />
            get key ideas instantly
          </Typography>
        </Box>
        <Box sx={{ flex: 1, position: "relative", display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <Box>
            <img src={chrome_icon} className="chrome-img" alt="Chrome Icon" style={{ position: "absolute", top: 0, left: -40, zIndex: 1 }} />
          </Box>
          <Button
            variant="contained"
            sx={{ px: { lg: 8, md: 6, sm: 4, xs: 2 }, py: 3, mt: { lg: 0, md: 0, sm: 3, xs: 0 }, fontSize: { lg: 30, md: 26, sm: 30, xs: 27 } }}

          >
            Install chrome extension
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          width: "94%",
          px: "3%",
          py: "4%",
          margin: "auto",
          flexDirection: { lg: "row", md: "column", sm: "column", xs: "column" },
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
          alignItems: { md: "initial", sm: "center", xs: "center" },
          bgcolor: "white",
          borderRadius: 6,
          border: "1px solid #ededed",
          boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.04),0px 3px 4px 0px rgba(0,0,0,0.06),0px 1px 8px 0px rgba(0,0,0,0.04)",
        }}

      >
        <Box sx={{ flex: 1, pr: { md: 3, sm: 0, xs: 0 }, }} >
          {/* <iframe id="video" className="iframe" src="https://www.youtube.com/embed/m6pJNW_jpw0" style={{ borderRadius: 18 }} frameborder="0" allowfullscreen></iframe> */}
          <YouTube
            videoId={"m6pJNW_jpw0"}
            opts={{
              height: '100%',
              width: '100%',
            }}
            className="iframe"
            id="video"
            onReady={onReady} 
            playerVars={{
              autoplay: 1
            }}
        
            />
        </Box>
        <Summary player={player} />


      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(LandingPage);

import React, { useEffect, useState } from 'react'
import { Alert, Box, Link, Typography } from '@mui/material'

import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/Container';
import { axios_get_call } from '../../components/AxiosCall';

const Dashboard = ({ user }) => {

  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  const [user_summaries, set_user_summaries] = useState(false);

  const navigate = useNavigate()


  const get_user_summaries = async () => {
    let url = '/summary/get-user-summaries/';
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      set_user_summaries(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  }

  useEffect(() => {
    get_user_summaries()
  }, [])

  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])

  return (
    user ? <Box>

      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity={alert.toLowerCase().includes('[error]') ? 'error' : 'info'}
          sx={{
            p: 2,
            mb: 2,
            width: 1,
            zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ''
      )}
      <Typography align='center' variant='h1'>Dashboard</Typography>
      <Box sx={{ my: 2 }}></Box>
      <Container bgcolor="white">
        <Box sx={{ width: 1, px: 3, minHeight: '500px', }}>
          {Object.keys(user_summaries).length > 0 ? Object.keys(user_summaries).map((summary_obj) => (
            <Box>
              <Box>
                <Typography variant='h2' align="left" sx={{  p: 0, m: 0, mt:2 }}>{summary_obj}</Typography>
              </Box>

              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: "#f9f9f9",
                borderRadius: 3,
                maxWidth: '88vw',
                overflow: "scroll",
                p:2


              }}>
                {user_summaries[summary_obj].map((summary) => {
                  return (
                    <Box
                      onClick={() => {
                        window.open(summary.summary_url, "_blank")
                      }}
                      sx={{
                        borderRadius: 4,
                        minWidth: '420px',
                        height: '268px',
                        overflow: "hidden",
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        textAlign: "center",
                        marginRight: 4,
                        position: 'relative',
                        '&:hover .textOverlay': {
                          display: 'flex', // Show the text on hover
                        },

                      }}>
                      <Box
                        className="textOverlay"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          display: 'none', // Initially hide the text
                          background: 'rgba(0, 0, 0, 0.8)', // Background color for the text overlay
                          color: 'white', // Text color
                          alignItems: 'center',
                          justifyContent: 'center',
                          p: 2,
                          cursor: "pointer"
                        }}
                      >
                        <Typography variant="subtitle2" align="center" sx={{ color: "white" }}>
                          {summary.title}
                        </Typography>
                      </Box>
                      <img src={summary.thumbnail} width={'100%'} />
                    </Box>
                  )
                })}
              </Box>

            </Box>
          )) : <Typography variant='subtitle2'>Any summary you create using the extension will be shown here. <Link href="https://www.youtube.com/watch?v=tNV8aV3hJhs" target='_blank'>Click here</Link>&nbsp;to try it out.</Typography>}
        </Box>
      </Container>
    </Box> : ''
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Dashboard);